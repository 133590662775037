<template>
    <v-container fluid class="white shadow1 rounded">
        <v-form class="custom-form" @submit.prevent="getProductLedger">
            <v-row dense justify="center" class="pb-4">
                <v-col cols="auto" md="4">
                    <v-row no-gutters style="margin-top: 3px;color: #000;align-items: center;">
                        <v-col cols="3">Product</v-col>
                        <v-col cols="9">
                            <v-combobox dense outlined hide-details v-model="product" @focus="$store.dispatch('product/getProducts')" :items="$store.getters['product/products']" :loading="$store.getters['product/loadingProducts']" item-text="display_text" item-value="id">
                            </v-combobox>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="auto" md="5">
                    <v-row no-gutters>
                        <v-col cols="6">
                            <v-row no-gutters style="margin-top: 3px;color: #000;align-items: center;">
                                <v-col cols="4">Date From</v-col>
                                <v-col cols="8">
                                    <v-menu>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field dense outlined hide-details v-model="filter.dateFrom" v-bind="attrs" v-on="on">
                                                <v-icon slot="prepend-inner">mdi-calendar-month</v-icon>
                                            </v-text-field>
                                        </template>
                                        <v-date-picker v-model="filter.dateFrom"></v-date-picker>
                                    </v-menu>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="6">
                            <v-row class="pl-3" no-gutters style="margin-top: 3px;color: #000;align-items: center;">
                                <v-col cols="4">Date To</v-col>
                                <v-col cols="8">
                                    <v-menu>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field dense outlined hide-details v-model="filter.dateTo" v-bind="attrs" v-on="on">
                                                <v-icon slot="prepend-inner">mdi-calendar-month</v-icon>
                                            </v-text-field>
                                        </template>
                                        <v-date-picker v-model="filter.dateTo"></v-date-picker>
                                    </v-menu>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="auto" md="1" class="d-flex align-center">
                    <v-btn type="submit" class="text_bg_fave" :loading="loading">Search</v-btn>
                </v-col>
            </v-row>
            <v-divider></v-divider>

            <v-row v-if="show">
                <v-btn @click="print">Print</v-btn>
                <v-col cols="12" id="printContent" class="d_table">
                    <v-simple-table>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Description</th>
                                    <th>Rate</th>
                                    <th>Stock In</th>
                                    <th>Stock Out</th>
                                    <th>Stock</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td></td>
                                    <td><strong>Opening Stock</strong></td>
                                    <td colspan="3"></td>
                                    <td class="text-right"><strong>{{ $store.getters['product/openingStock'] }}</strong></td>
                                </tr>

                                <tr v-for="(product, ind) in $store.getters['product/ledgers']" :key="ind">
                                    <td class="text-center">{{ product.date }}</td>
                                    <td>{{ product.description }}</td>
                                    <td class="text-right">{{ product.rate }}</td>
                                    <td class="text-right">{{ product.in_quantity }}</td>
                                    <td class="text-right">{{ product.out_quantity }}</td>
                                    <td class="text-right">{{ product.stock }}</td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-col>
            </v-row>
            <div v-else class="no_result">
                <div class="img"></div>
            </div>
            <div id="printTable" style="display: none;">
                <table class="bordered-table">
                    <tr>
                        <th>Date</th>
                        <th>Description</th>
                        <th>Rate</th>
                        <th>Stock In</th>
                        <th>Stock Out</th>
                        <th>Stock</th>
                    </tr>
                    <tr>
                        <td></td>
                        <td><strong>Opening Stock</strong></td>
                        <td colspan="3"></td>
                        <td class="text-right"><strong>{{ $store.getters['product/openingStock'] }}</strong></td>
                    </tr>

                    <tr v-for="(product, ind) in $store.getters['product/ledgers']" :key="ind">
                        <td class="text-center">{{ product.date }}</td>
                        <td>{{ product.description }}</td>
                        <td class="text-right">{{ product.rate }}</td>
                        <td class="text-right">{{ product.in_quantity }}</td>
                        <td class="text-right">{{ product.out_quantity }}</td>
                        <td class="text-right">{{ product.stock }}</td>
                    </tr>
                </table>
            </div>
        </v-form>
    </v-container>
</template>

<script>
export default {
    name: 'ProductLedger',

    data: () => ({
        filter: {
            dateFrom: new Date().toISOString().substr(0, 10),
            dateTo: new Date().toISOString().substr(0, 10),
            productId: null
        },
        product: null,
        loading: false,
        show: false,
    }),

    watch: {
        product(product) {
            if (product == undefined) return;
            this.filter.productId = product.id;
        }
    },

    methods: {
        async getProductLedger() {
            if (this.product == null) {
                this.$store.dispatch('snackbar/error', 'Select a product');
                return;
            }

            this.loading = true;

            await this.$store.dispatch('product/getProductLedger', this.filter)

            this.show = true;
            this.loading = false;
        },
        async print() {
            let invoiceContent = document.querySelector('#printTable').innerHTML;
            let printWindow = window.open('', 'PRINT', `width=${screen.width}, height=${screen.height}, left=0, top=0`);
            let companyProfile = this.$store.getters['companyProfile/company']
            let companyImage = companyProfile != null ? this.$store.getters['companyProfile/company'].image : ''
            let ImagePath = companyImage ? this.apiBasePath + '/' + companyImage : '';
            printWindow.document.write(`
                <!DOCTYPE html>
                <html lang="en">
                <head>
                    <meta charset="UTF-8">
                    <meta name="viewport" content="width=device-width, initial-scale=1.0">
                    <meta http-equiv="X-UA-Compatible" content="ie=edge">
                    <title>Investigation Payment Slip</title>
                    <style>
                    ${this.setStyle()}
                    </style>
                </head>
                <body>
                    <div>
                        <div class="container">
                            <table style="width:100%;">
                                <thead>
                                    <tr>
                                        <td>
                                            <div class="custom-row">
                                                <div class="col-xs-2">
                                                    <img src="`+ ImagePath + `" alt="Logo" style="height:90px; width:90%;object-fit: contain;" />
                                                </div>
                                                <div class="col-xs-10">
                                                    <strong style="font-size:18px;">${companyProfile != null ? companyProfile.company_name : ''}</strong><br>
                                                    <p style="white-space:pre-line;margin:0px">Address : ${companyProfile != null ? companyProfile.address : ''}</p>
                                                    <p style="margin:0px">Mobile : ${companyProfile != null ? companyProfile.contact_us : ''}</p>
                                                    <p style="margin:0px">E-Mail : ${companyProfile != null ? companyProfile.email : ''}</p>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div class="custom-row">
                                                <div class="invoice-title">
                                                    Product Ledger
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div class="custom-row">
                                                <div class="col-xs-12">
                                                    ${invoiceContent}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td>
                                            <div style="width:100%;height:50px;">&nbsp;</div>
                                        </td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>    
                    
                </body>
                </html>
            `);

            printWindow.moveTo(0, 0);

            printWindow.focus();
            await new Promise(resolve => setTimeout(resolve, 1000));
            printWindow.print();
            printWindow.close();
        },

        setStyle() {
            return `
            body {
                font-family: Arial,sans-serif;
            }
            .bordered-table {
                border-collapse: collapse;
                width: 100%;
                }

                .bordered-table th,
                .bordered-table td {
                border: 1px solid black;
                padding: 8px;
                text-align: left;
                }

                .bordered-table thead th {
                background-color: #f2f2f2;
                }

                .bordered-table tbody tr:nth-child(even) {
                background-color: #f9f9f9;
                }
                .custom-row {
                    width: 100%;
                    display: block;
                }
                .print-btn a{
                    background: #CFD8DC;
                    display: inline-block;
                    padding: 3px 13px;
                    border-radius: 5px;
                    color: #000 !important;
                }
                .print-btn a:hover {
                    background: #B0BEC5;
                }
                .invoice-title {
                    text-align: center;
                    font-weight: bold;
                    font-size: 15px;
                    margin-bottom: 15px;
                    padding: 5px;
                    border-top: 1px dotted #454545;
                    border-bottom: 1px dotted #454545;
                }
                .col-xs-12 {
                    width: 100%;
                }
                .col-xs-10 {
                    width: 80%;
                    float: left;
                }
                .col-xs-9 {
                    width: 70%;
                    float: left;
                }
                .col-xs-7 {
                    width: 60%;
                    float: left;
                }
                .col-xs-6 {
                    width: 50%;
                    float: left;
                }
                .col-xs-5 {
                    width: 40%;
                    float: left;
                }
                .col-xs-4 {
                    width: 35%;
                    float: left;
                }
                .col-xs-3 {
                    width: 30%;
                    float: left;
                }
                .col-xs-2 {
                    width: 20%;
                    float: left;
                }
                .b-text {
                    font-weight: 500;
                    font-size: 15px;
                }
                .normal-text {
                    font-size: 14px;
                    margin: 0px;
                }
                .invoice-details {
                    width: 100%;
                    border-collapse: collapse;
                    border:1px solid #ccc;
                }
                .invoice-details thead {
                    font-weight: 500;
                    text-align:center;
                }
                .invoice-details tbody td{
                    padding: 0px 5px;
                }
                .text-center {
                    text-align: center;
                }
                .text-right {
                    text-align: right;
                }
                .line {
                    border-bottom: 1px solid #ccc;
                    margin-top: 15px;
                    margin-bottom: 15px;
                }
                .paid-text {
                    padding:30px 0px;
                }
                .mt-60 {
                    margin-top: 60px;
                }
                .mr-20 {
                    margin-right: 20px;
                }
                .ml-20 {
                    margin-left: 20px;
                }
                .ft-fiext {
                    position: fixed;
                    bottom: 0;
                }
                @media print {
                    .hide {
                        display: none
                    }
                    .v-data-table > .v-data-table__wrapper > table {
                        width: 100%;
                        border-spacing: 0;
                    }
                    .theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
                        font-size: 13px;
                        padding: 0px 1px;
                        color: #000;
                        border: 1px solid #ccc;
                        text-align: center;
                        height: 24px !important;
                    }
                    .v-data-table--dense > .v-data-table__wrapper > table > tbody > tr > td {
                        height: 20px !important;
                        padding: 0px 5px !important;
                        font-size: 12px !important;
                        border: 1px solid #ccc !important;
                    }
                }
                .cls {
                    clear: both;
                }
            `;
        },
    }
}

</script>

<style lang="scss" scoped>
.v-icon.v-icon {
    font-size: 18px !important;
    top: 3px !important;
}

.d_table>thead>tr:last-child>th {
    font-size: 11px;
    padding: 0px 1px;
    border: 1px solid #ddd !important;
    text-align: center;
}

.theme--light.v-data-table>.v-data-table__wrapper>table>thead>tr:last-child>th {
    border: 1px solid #ddd;
}

.d_table table>tbody>tr>td {
    // height: 20px !important;
    padding: 0px 5px !important;
    font-size: 12px !important;
    border: 1px solid #ddd !important;
}
</style>