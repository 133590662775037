<template>
    <v-container fluid class="white shadow1 rounded">
        <v-row no-gutters justify="center">
            <v-col cols="8">
                <v-form class="custom-form pb-5" @submit.prevent="addVariant">
                    <v-row>
                        <v-col cols="12">
                            <v-row no-gutters style="margin-top: 3px;color: #000;align-items: center;">
                                <v-col cols="4">Product Id</v-col>
                                <v-col cols="8">
                                    {{ product.display_text }}
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="5">
                            <v-row no-gutters style="margin-top: 3px;color: #000;align-items: center;">
                                <v-col cols="4">Color</v-col>
                                <v-col cols="8">
                                    <v-combobox dense outlined hide-details v-model="color" :items="this.colors" item-text="name" item-value="id">
                                    </v-combobox>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="5">
                            <v-row no-gutters style="margin-top: 3px;color: #000;align-items: center;">
                                <v-col cols="4">Size</v-col>
                                <v-col cols="8">
                                    <v-combobox dense outlined hide-details v-model="size" :items="this.sizes" item-text="name" item-value="id">
                                    </v-combobox>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="2">
                            <div>
                                <v-btn type="submit" :loading="loading" dark block color="light-blue darken-2">Add variant</v-btn>
                            </div>
                        </v-col>
                    </v-row>
                    <v-divider></v-divider>
                    <div style="width: 100%;">
                        <v-simple-table>
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th>SL</th>
                                        <th>Product Name</th>
                                        <th>Color</th>
                                        <th>Size</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, ind) in $store.getters['variant/variants']" :key="ind">
                                        <td class="text-center" style="width:5%">{{ ind + 1 }}</td>
                                        <td>{{ item.product.name }}</td>
                                        <td class="text-right" style="width:18%">{{ item.color.name }}</td>
                                        <td class="text-right" style="width:10%">{{ item.size.name }}</td>
                                        <td class="text-center" style="width:7%">
                                            <v-icon small @click="removeVariant(ind)" color="error">mdi-delete-circle-outline</v-icon>
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </div>
                </v-form>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import VueBarcode from '@chenfengyuan/vue-barcode';
export default {
    name: 'ProductVariant',

    components: {
        'vue-barcode': VueBarcode
    },

    data: () => ({
        product: {
            id: '',
            code: '',
            name: '',
            display_text: '',
            sale_rate: 0,
            quantity: 0,
            article: '',
            is_variant: 0,
        },
        color: null,
        colors: [],
        sizes: [],
        size: null,
        loading: false,
        cart: []
    }),

    async created() {
        await this.$store.dispatch('product/getProducts', { id: this.$route.params.id })
        await this.$store.dispatch('color/getColors')
        await this.$store.dispatch('size/getSizes')
        this.product = await this.$store.getters['product/products'][0]
        this.colors = await this.$store.getters['color/colors']
        this.sizes = await this.$store.getters['size/sizes']
        await this.$store.dispatch('variant/getVariants', {
            product_id: this.product.id
        })
    },

    methods: {
        async removeVariant(index) {
            if (confirm('Are you sure to delete this variant')) {
                await this.$store.dispatch('variant/deleteVariant', this.product.id)
                await this.$store.dispatch('variant/getVariants', {
                    product_id: this.product.id
                })
            }
        },
        async addVariant() {

            if (!this.color || !this.size) {
                this.$store.dispatch("snackbar/error", "Select size and color");
                // return item.color.id == this.color.id && item.size.id == this.size.size;
                return;
            }
            // check existing
            let exist = this.cart.filter(item => {
                return item.color.id == this.color.id && item.size.id == this.size.id;
            })
            if (exist.length) {
                this.$store.dispatch("snackbar/error", "This color and size already exist", 3000);
                return;
            }
            // this.$store.dispatch("variant/saveVariant")

            this.$store.dispatch('variant/saveVariant', {
                product_id: this.product.id,
                color_id: this.color.id,
                size_id: this.size.id
            });
            this.color = null;
            this.size = null;
            await this.$store.dispatch('variant/getVariants', {
                product_id: this.product.id
            })
        },

        // saveVariant() {
        //     this.$store.dispatch('variant.saveVariant', this.cart)
        // }

    }
}
</script>

<style lang="scss" scoped>
.bacode-area {
    width: 175px;
    border: 1px solid #ccc;
    padding: 3px;
    margin: 0;
    text-align: center;
    float: left;
    margin-right: 2px;
    margin-bottom: 2px;
}

img {
    width: 120px;
    height: 50px;
}

h5 {
    margin-top: -12px;
}

p {
    padding: 0px 5px;
    margin: 0px;
}
</style>